<template>
<section class="dashboard-header">
        <div class="container-fluid">  
            <div class="card-header">
                <div class="row">
                    <div class="col-10">
                        <h2>{{msg}}</h2>
                    </div>
                    <div class="col-2">   
                        
                    </div>
                </div>
            </div>

            <div id="incidencia" class="row bg-white has-shadow">
                <div class="col-md-8 offset-md-1">
                    <form class="form">
                        <vue-form-generator :schema="schema" :model="model">
                        </vue-form-generator>

                        <div class="row">
                            <div class="col-7">
                                <b-btn class="mr-3 ml-3 mt-3" variant="secundary" @click="regresar">Regresar</b-btn>
                                <b-btn class="mr-3 ml-3 mt-3" variant="primary" @click="grabar">Grabar</b-btn>                        
                            </div>
                            
                            <b-alert
                                class="col-5 mt-2"              
                                :show="dismissCountDown"
                                dismissible
                                variant="warning"
                                @dismissed="dismissCountDown=0"
                                @dismiss-count-down="countDownChanged"
                            >
                            Se grabó correctamente.
                            </b-alert>                                  
                        </div>                        
                    </form>
                </div>
                <div class="col-md-3 mt-2">
                    <div class="row">
                        <div class="col-md-12">
                            <avatar 
                                username="Jane Doe"
                                :src="imageData"
                                :size = "120"
                            ></avatar>
                        </div>                    
                    </div>
                    <div class="row">
                        <div class="col-md-8 mt-3">
                            <button class="btn btn-link ml-2" @click='toggle = !toggle'>Subir foto </button>
                        </div>
                    </div>
                    <div class="row" v-show='toggle'>
                        <div class="col-md-8 mt-1">                            
                            <div class="file-upload-form">
                                <input id="upload" type="file" @change="previewImage" >
                            </div>                                                        
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </section>
    
</template>

<script>
import axios from 'axios';
import Vue from 'vue'
import VueFormGenerator from 'vue-form-generator'
import Avatar from 'vue-avatar'
import Compress from 'compress.js'

let compress = new Compress();

//import 'vue-form-generator/dist/vfg.css' 
Vue.use(VueFormGenerator)

export default {
     mounted: function() {
        this.previewImage();
        this.getPhoto();
      },
    data() {
        return {
            toggle: false,
            msg: 'Editar Usuario',
            tabla: 'usuarios',
            imageData: "",
            model: this.$route.params.model,
            schema: this.$route.params.fields,  
            dismissSecs: 1,
            dismissCountDown: 0,                      
        }        
    },
    components: {
      Avatar
    },
    methods: {
        previewImage: function(event) {
            compress.attach('#upload', {
                maxWidth: 400,
                quality: .99
            }).then((data) => {
                var image = data[0];
                this.imageData = image.prefix + image.data; 
                var emailUser = btoa(this.model.email) + '.ava';
                var datos = {"nameFile":'', "emailUser": emailUser, "avatar": image.data};
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
                axios.post(process.env.VUE_APP_ROOT_API + "api/secure/" + this.tabla + "/uploadavatar", datos)
                .then((response) => {                
                    this.toggle = false;
                    //console.log(JSON.stringify(response.data));
                    //this.$router.replace({ path: '/portal/' + this.tabla });                                                                 
                }, (error) => {
                    console.log("Error: " + JSON.stringify( error.response.data.message) );
                })
            })
            
        },
        
        getPhoto(){
            var emailUser = btoa(this.model.email) + '.ava';
            var datos = {"nameFile":'', "emailUser": emailUser, "avatar": ''};
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
            axios.post(process.env.VUE_APP_ROOT_API + "api/secure/" + this.tabla + "/avatar", datos)
            .then((response) => {                
                this.imageData = "data:image/jpeg;base64," + response.data.avatar;
                //this.$router.replace({ path: '/portal/' + this.tabla });                                                                 
            }, (error) => {
                console.log("Error: " + JSON.stringify( error.response.data.message) );
            })
        },
         countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },
        grabar(){          
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
            axios.post(process.env.VUE_APP_ROOT_API + "api/secure/" + this.tabla, this.model)
            .then((response) => {                
                //this.$router.replace({ path: '/portal/' + this.tabla }); 
                this.dismissCountDown = this.dismissSecs                                                                
            }, (error) => {
                console.log("Error: " + JSON.stringify( error.response.data) );
            })
        },
        regresar(){
            this.$router.replace({ path: '/portal/' + this.tabla });
            //.go(-2);
        }
    }

}
</script>

<style>
    small.text-info{color: #7000ff}
    div#incidencia{margin-top: 10px; padding-bottom: 20px;}
    section.dashboard-header{padding: 10px 0;}  
    div.autocomplete{width: 400px}

    
</style>
